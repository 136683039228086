.uploadMain {
  display: flex;
  flex-direction: row;
}

//.gelFileUpload {
//  margin: 20px;
//  max-width: 100%; //500px;
//  border: 2px dotted #a4a4a4;
//  border-radius: 5px;
//  display: flex;
//  flex-direction: row;
//  align-items: center;
//  background-color: #dfdfdf;
//}

//.wbFileUpload {
//  margin-top: 2px;
//  margin-left: 20px;
//  margin-right: 20px;
//  margin-bottom: 20px;
//  max-width: 100%; //500px;
//  border: 2px dotted #a4a4a4;
//  border-radius: 5px;
//  display: flex;
//  flex-direction: row;
//  align-items: center;
//  background-color: #dfdfdf;
//}
//
//.wbfile-drop {
//  /* relatively position the container bc the contents are absolute */
//  position: relative;
//  height: 60px;
//  width: 100%;
//  color: #666;
//  font-size: 13px;
//}
//.wbfile-drop > .wbfile-drop-target {
//  /* basic styles */
//  position: absolute;
//  top: 0px;
//  left: 0;
//  height: 100%;
//  width: 100%;
//  border-radius: 2px;

//  /* horizontally and vertically center all content */
//  display: flex;
//  flex-direction: row;
//  align-items: center;
//  justify-content: left;
//  align-content: center;
// text-align: left;
//}
//.wbfile-drop > .wbfile-drop-target.wbfile-drop-dragging-over-target {
//  /* overlay a black mask when dragging over the frame */
//  border: none;
//  background-color: rgba(37, 138, 188, 0.507);
//  box-shadow: none;
//  z-index: 50;
//  opacity: 1;
//  /* typography */
//  color: white;
//}
//
//.stageUnderline {
//  text-decoration: underline;
//  padding-left: 10px;
//}

//.gelfile-drop {
//  /* relatively position the container bc the contents are absolute */
//  position: relative;
//  height: 60px;
//  width: 100%;
//  color: #666;
//  font-size: 13px;
//  //cursor: pointer;
//}
//.gelfile-drop > .gelfile-drop-target {
//  /* basic styles */
//  position: absolute;
//  top: 0px;
//  left: 0;
//  height: 100%;
//  width: 100%;
//  border-radius: 2px;
//
//  /* horizontally and vertically center all content */
//  display: flex;
//display: -webkit-box;
//display: -webkit-flex;
//display: -ms-flexbox;

// flex-direction: row;
//-webkit-box-orient: vertical;
//-webkit-box-direction: normal;
//-webkit-flex-direction: column;
//-ms-flex-direction: column;

// align-items: center;
//-webkit-box-align: center ;
//-webkit-align-items: center ;
//-ms-flex-align: center ;

// justify-content: left;
//-webkit-box-pack: left center;
//-webkit-justify-content: left ;
//-ms-flex-pack: left ;

// align-content: center;
//-webkit-align-content: left ;
//-ms-flex-line-pack: left ;

// text-align: left;
//}
//.gelfile-drop > .gelfile-drop-target.gelfile-drop-dragging-over-target {
//  /* overlay a black mask when dragging over the frame */
// border: none;
// background-color: rgba(37, 138, 188, 0.507);
//  box-shadow: none;
//  z-index: 50;
//  opacity: 1;
//
//  /* typography */
//  color: white;
//}
//.fileUploadTable {
//  margin-top: 2px;
//  margin-bottom: 20px;
//  margin-left: 20px;
//  margin-right: 20px;
//  padding-bottom: 5px;
//}
//.uploadTables {
//  width: auto;
//  min-height: 100px;
//  max-height: 300px;
//  overflow-y: auto;
//}
.uploadDefinitions {
  margin-left: 20px;
}
.uploadType {
  margin-left: 20px;
  margin-bottom: 10px;
}
.uploadAndComment {
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 15px;

  //padding-top: 10px;
  //width:  200px;
  //display: flex;
  //flex-direction: row;
}
.uploadComment {
  margin-top: 0px;
}
.uploadSubmit {
  padding-top: 2px;
  padding-left: 10px;
  //margin-bottom:5px;
}
//.stagingHeader {
//  display: flex;
//  flex-direction: row;
//}
//.stagingHeaderText {
//  width: 90%;
//}
//.stagingHeaderRefresh {
//  margin-top: 12px;
//}
//.statusHeader {
//  display: flex;
//  flex-direction: row;
//}
//.statusHeaderText {
//  width: 20%;
//}
.statusHeaderSync {
  margin-top: 12px;
}
//.uploadStatusContent {
//  display: flex;
//  flex-direction: row;
//  width: 100%;
//}
//.uploadStatusTables {
//  display: flex;
//  flex-direction: column;
//  width: 50%;
//}
//.uploadThumbs {
//  width: 50%;
//  margin-right: 10px;
//}

//.currentUploadTable {
//  margin-top: 2px;
//  margin-bottom: 20px;
//  margin-left: 20px;
//  margin-right: 20px;
//  padding-bottom: 5px;
//}

//.thumbnailContent {
//  margin-left: 25px;
//  margin-right: 20px;
//  //height: 200px;
//}
.recentUploadTable {
  margin-top: 2px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 5px;
}

@media (min-width: 900px) {
  .css-1lx6jqs {
    flex-direction: unset !important;
  }
  .css-uip01m-MuiStack-root {
    flex-direction: row !important;
  }
}

.css-1ai306e {
  display: block;
  width: 100% !important;
}

.css-9u4gdx {
  width: 15vw;
}

///CRAZY/////
