.App {
  .content {
    padding-left: 65px;
    height: 100vh;
    //overflow-y: scroll;
    //heigth: 100vh;

    .homeDiv {
      border-radius: 10px;
      margin-top: 15px;
      padding: 15px 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      display: none;
      &.home {
        display: flex;
      }
    }
    > div {
      margin-top: 15px;
      padding: 15px 10px;
      box-sizing: border-box;
    }
  }
  &.true {
    //handles the menu opening and closing
    .content {
      padding-left: calc(95px + 73px);
    }
    .main-menu {
      width: 155px;
      .iconLabel {
        width: 154px;
        div.label {
          display: block;
        }
      }
    }
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  padding: 20px 150px;
}

a {
  color: white;
  text-decoration: none;
  font-style: italic;
  font-size: 20px;
}
