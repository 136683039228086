//.analyticsMain {
//  display: flex;
//  flex-direction: row;
//}
//.stagingHeader{
//    display: flex;
//    flex-direction: row;
//}
.recentUploadTable {
  margin-top: 2px;
  margin-left: 7px;
  margin-right: 7px;
  padding-bottom: 5px;
}

.currentUploadTable {
  margin-top: 2px;
  margin-bottom: 20px;
  margin-left: 7px;
  margin-right: 7px;
  padding-bottom: 5px;
}
.testSelect {
  flex-grow: 1;
  min-height: 30px;
}
.imageFiltersContent {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}
.imageInfoContent {
  margin-left: 10px;
}
.imageAnalyticsContent {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}
.FandIContent {
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: rgb(56, 56, 56);
}
.imageFilters {
  width: 100%;
  background-color: rgb(56, 56, 56);
}
.imageInfo {
  width: 100%;
  background-color: rgb(56, 56, 56);
}
.imageAnalytics {
  width: 50%;
  margin-left: 15px;
  background-color: rgb(56, 56, 56);
}

.imageContentR1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: rgb(56, 56, 56);
}
.analyticsCheckBoxes {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  background-color: rgb(56, 56, 56);
}
.submitSaveShareContent {
  display: flex;
  flex-direction: row;
  background-color: rgb(56, 56, 56);
}
.saveShareContent {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-left: 10px;
}
.allImageContent {
  width: 100%;
}
.displaySelectedImages {
  margin-bottom: 10px;
  width: 100%;
}
.displayImageInfoContent {
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 475px;
  overflow: auto;
}
.displayImageContent {
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.tagElements {
  margin-left: 30px;
}

.view-and-mask-selected-image {
  display: flex;
  flex-direction: column;
}

.widthOfCanvas {
  width: 100%;
}

.displayContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-left: 15px;
  margin-bottom: 10px;
  margin-right: 10px;
  //justify-content: center;
  //align-items:center
}

.displayImage0Content.displayImage1Content {
  display: flex;
  flex-direction: row;
}
.bgColorTest {
  background-color: rgb(58, 58, 58);
  border-top: solid;
  border-width: 1px;
  border-color: #399dbc;
  padding: 5px;
}

.test {
  right: 0px;
}

.refreshPrior {
  padding-top: 14px;
}

.testoverflow {
  overflow: scroll;
}
.chooseFileBoxStyle {
  min-height: 260px;
  width: 22%;
  box-shadow: 0 0 10px rgba(132, 130, 130, 0.43);
  border-radius: 10px;
  margin-right: 5px;
}

.sectionTitleStyle {
  padding: 15px;
  color: #666;
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.alertTitleStyle {
  color: "#666";
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.analyticsUploadStyle {
  margin-right: 2; //mr:2
  color: #666;
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.tableHeadRowBGStyle {
  background-color: "#dfdfdf";
  border-bottom: solid;
  border-width: "1.1px";
  border-color: "#666";
}

.tableHeadRowTextStyle {
  padding-left: "0px";
  color: "#666";
  font-size: "13px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.tableStickyHeadRowTextStyle {
  background-color: "#dfdfdf";
  padding-left: "0px";
  color: "#666";
  font-size: "13px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.tableRowStyle {
  padding-left: "0px";
  color: "#399dbc";
  font-size: "13px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.imageInfoValueStyle {
  padding-left: "0px";
  color: "#000000";
  font-size: "13px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.tableRowMLClassStyle {
  padding-left: "0px";
  color: "#7000ff";
  font-size: "13px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.alertMLClassStyle {
  padding-left: "0px";
  text-decoration: "underline";
  color: "#7000ff";
  font-size: "14px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.alertFilenameStyle {
  padding-left: "0px";
  color: "black";
  font-size: "14px";
  font-weight: "400";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.tableRowLinkStyle {
  //&:hover : { 'cursor': 'pointer' };
  padding-left: "0px";
  color: "#399dbc";
  font-size: "13px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.refreshTableStyle {
  padding: "0px";
  color: "#adadad";
  background-color: "#dfdfdf";
}

.accordionHeaderStyle {
  background-color: "#0042801c";
  padding-left: "0px";
  color: "#666";
  font-size: "13px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.objectRegHeaderStyle {
  margin-left: 2; //ml
  margin-top: 1.2; //mt
  margin-bottom: 0.5; //mb
  text-align: "center";
  color: "white";
  font-size: "14px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.chartPaperStyle {
  background-color: "#111";
}
.laneChartPaperStyle {
  background-color: "#111";
}

.gridStyle {
  background-color: "white";
}

.innerAccordionHeaderStyle {
  background-color: "#0900801c";
  padding-left: "0px";
  color: "#666";
  font-size: "13px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.imageInfoKeyStyle {
  padding-left: "0px";
  color: "#780000";
  font-size: "13px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.regionsOfInterestHeader {
  color: "#666";
  font-size: "15px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.regionsOfInterestStatus {
  font-size: "13px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.currentAnalysisStatus {
  padding: "0px";
  border: "0px";
  margin: "0px";
  font-size: "14px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.priorAnalysisStatusStyle {
  font-size: "14px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.genericImageNameStyle {
  color: "#88e5ff";
  font-size: "14px";
  font-weight: "300";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

a {
  margin: 0 !important;
  color: #1976d2 !important;
  -webkit-text-decoration: underline !important;
  text-decoration: underline !important;
  text-decoration-color: rgba(25, 118, 210, 0.4) !important;
  padding-left: 0px !important;
  /* color: #399dbc; */
  font-size: 13px !important;
  font-weight: 300 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}
