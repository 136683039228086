.main-menu {
  > * {
    // margin: 10px 0;
    //color: #6d7687;
    //cursor: pointer !important;
    &.iconLabel {
      //display: flex;
      //flex-direction: row;
      //align-items: center;
      //text-align: center;
      //width: 50px;
      //cursor: pointer !important;
      //padding: 10px 0 4px 0;
      //border-bottom: 2px solid transparent;
      //background: #ffffff00;
      //margin-top: 10px;
      //height: 32px;
      //margin-bottom: 4px;
      //margin-left: 1px;
      //place-content: center;
      img {
        width: 35px;
        &.normal {
          display: block;
        }
        &.selected {
          display: none;
        }
      }
      &.active {
        border-bottom: 2px solid #00a650;
        color: #00a650;
        img {
          &.normal {
            display: none;
          }
          &.selected {
            display: block;
          }
        }
      }
      //div.label {
      //  margin-top: 4px;
      //  width: 65px;
      //  display: none;
      //  text-align: left;
      //  padding-left: 10px;
      //  font-size: 15px;
      //}
      //* {
      //  cursor: pointer !important;
      //}
      &.toggle {
        img {
          width: 20px;
        }
        &.active {
          border-bottom: none;
          img {
            &.normal {
              display: none;
            }
            &.selected {
              display: block;
            }
          }
        }
      }
    }
  }
}
