.analyticsMain{
    display: flex;
    flex-direction: row;        
}
.stagingHeader{
    display: flex;
    flex-direction: row;
}
.recentUploadTable{
    margin-top:2px;
    
    margin-left:7px;
    margin-right:7px;
    padding-bottom:5px
}

.currentUploadTable{
    margin-top:2px;
    margin-bottom:20px;
    margin-left:7px;
    margin-right:7px;
    padding-bottom:5px
}
.testSelect{
    flex-grow: 1;
    min-height: 30px;
}
.imageFiltersContent{
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    
}
.imageInfoContent{
    margin-left: 10px;
}
.imageAnalyticsContent{
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    
}
.FandIContent{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.imageFilters{
    width: 100%;
}
.imageInfo{
    width: 100%;
}
.imageAnalytics{
    width:50%;
    margin-left: 15px;
}
.imageContentR1{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.analyticsCheckBoxes{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.submitSaveShareContent{
    display: flex;
    flex-direction: row;
}
.saveShareContent{
    display: flex;
    flex-direction: row;
    width: 50%;
    margin-left: 10px;
}
.allImageContent{
    width: 100%;
}
.displaySelectedImages{
    margin-bottom: 10px;
    width: 100%;
    
}
.displayImageInfoContent{
    margin-bottom:10px;
    margin-left:5px;
    margin-right: 5px;
    max-height: 475px;
    overflow: auto;
}
.displayImageContent{
    margin-bottom:10px;
    margin-left:5px;
    margin-right: 5px;
}

.tagElements{
    margin-left: 30px;
}

.view-and-mask-selected-image{
    display: flex;
    flex-direction: column;    
}

.widthOfCanvas{
    width: 100%;
}

.displayContent{
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 10px;
    margin-right: 10px;
    //justify-content: center;
    //align-items:center        
}

.displayImage0Content.displayImage1Content{
    display: flex;
    flex-direction: row;
    
    
}
.bgColorTest{
    background-color: black;
    padding: 5px;
}   

.test{
    
    right: 0px;
}

.refreshPrior{
    padding-top: 14px;
}

.testoverflow{
    overflow: scroll;
}

